import './fullscreen-teaser.scss';
import { Slider } from '../../components/slider/slider';

class FullscreenTeaser {
    constructor (element) {
        this.$fullscreenTeaser = element;
        this.$sliderItems = this.$fullscreenTeaser.querySelectorAll('[data-fullscreen-teaser="slide"]');
    }

    initialize () {
        if (this.$sliderItems.length > 1) {
            this.setSlider();
        }
    }

    setSlider () {
        this.$fullscreenTeaser = new Slider(this.$fullscreenTeaser, {
            speed: 500,
            indicators: true,
            slidesPerView: 1,
            autoHeight: true,
            effect: 'fade',
            centeredSlides: true,
            centeredSlidesBounds: true,
            initAttr: 'data-fullscreen-teaser'
        });
    }
}

export { FullscreenTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $fullscreenTeaser = $context.querySelectorAll('[data-fullscreen-teaser="root"]');

        for (let i = 0; i < $fullscreenTeaser.length; i++) {
            const $$fullscreenTeaser = new FullscreenTeaser($fullscreenTeaser[i]);
            $$fullscreenTeaser.initialize();
        }
    }
});
